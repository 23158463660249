import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Stack} from "@mui/material";
import BatterySaverRoundedIcon from '@mui/icons-material/BatterySaverRounded';


const CircularProgressWithLabel = ({state, state_percent}) => {

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant='determinate' value={state_percent ? parseFloat(state_percent) : 0} size='16.5rem' />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Stack>
                    <Typography variant="progress" component="div" color="white">
                        {`${Math.round(state_percent)}%`}
                    </Typography>
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        <BatterySaverRoundedIcon sx={{color: '#8AE669'}}/>
                        <Typography variant="progressState" component="div" color="white">
                            {state}
                        </Typography>
                    </Stack>

                </Stack>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel