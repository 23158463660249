const components = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 30,
            }
        },
        variants: [
            {
                props: {variant: 'contained'},
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: '#EA495E',
                    color: '#fff',
                    padding: '12px 16px',
                    gap: '10px',
                    '&:hover': {
                        background: '#EF7686',
                    },
                    '&:active': {
                        background: '#C6394C'
                    },
                    '&:disabled': {
                        background: '#8CA5AE'
                    }
                },
            }, {
                props: {variant: 'outlined'},
                style: {
                    textTransform: 'none',
                    border: '2px solid #EF7686',
                    background: 'transparent',
                    color: '#EF7686',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: 'rgba(239,118,134,0.3)',
                        border: '2px solid #EF7686',
                    },
                    '&:active': {
                        background: '#DFF1E7',
                        border: '2px solid #EA495E',
                    }
                },
            }, {
                props: {variant: 'text'},
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: 'transparent',
                    color: '#EF7686',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: '#EFF8F3',
                    },
                    '&:active': {
                        background: '#DFF1E7',
                    }
                },
            },
        ],
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 8,
                backgroundColor: '#F3F5F7',
            }
        }
    },
    MuiInput: {
        styleOverrides: {
            root: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
                '&:hover': {
                    content: 'none',
                }
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                border: '1px solid #F3F3F1',
                background: '#fff',
                borderRadius: '16px'
            },
            cell: {
                border: 'none'
            },
        }
    }
}

export {components}