import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from "@mui/material";
import heatVentorsLogo from "../assets/logos/logo.png"


function UnauthenticatedAppBar() {

    const theme = useTheme();


    const style = {
        background: theme.palette.background.paper,
        color: theme.palette.text.secondary,
    }

    return (
        <AppBar position="sticky" sx={style}>
            <Container maxWidth="xl">

                <Toolbar disableGutters sx={{justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Dinobooking">
                            <IconButton sx={{p: 0}} onClick={() => window.location.href = 'https://heatventors.com'}>
                                <img src={heatVentorsLogo} alt="Heatventors logo" width="300px"/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default UnauthenticatedAppBar;