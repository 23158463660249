import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

// const data = [
//     {
//         name: 'Jan',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Feb',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Mar',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Apr',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Maj',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Jun',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Jul',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
//     {
//         name: 'Aug',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Sep',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Oct',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
//     {
//         name: 'Nov',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Dec',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ];

// const MyBarChart = ({data}) => {
const MyBarChart = ({data}) => {

    return <div style={{position: 'relative', width: '100%', paddingBottom: '550px'}}>
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            }}
        ><ResponsiveContainer width="99%" height="100%">
        <BarChart
            width={500}
            height={800}
            data={data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="month"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Bar dataKey="sum_energy_consumption_with_heattank" name="Energy consumption with HeatTank" stackId="a" fill="#5ABDD7" barSize={20} radius={[0, 0, 10, 10]}/>
            <Bar dataKey="sum_energy_consumption_without_heattank" name="Energy consumption without HeatTank" stackId="a" fill="#EA495E" barSize={20} radius={[10, 10, 0, 0]}/>
        </BarChart>
        </ResponsiveContainer>
        </div>
    </div>
}

export default MyBarChart
