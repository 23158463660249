import {Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import greenLogo from "../assets/logos/logo.png"
import {HashLink} from "react-router-hash-link";
import SocialButton from "./SocialButton";


const DinoFooter = () => {
    const theme = useTheme()

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer">
        <div className="dino-footer-content">
            <Stack justifyContent='space-between' gap={3} direction={{xs: 'column', md: 'row', xl: 'row'}}>

                <Stack xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Contact</Typography>
                        <Typography variant='contact' color={theme.palette.primary[500]}>+36 20 822 7134</Typography>
                        <Typography variant='contact' color={theme.palette.primary[500]}>info@heatventors.com</Typography>
                        <Typography variant='contact' onClick={() => window.open('https://www.heatventors.com', '_blank')} color={theme.palette.primary[500]}>heatventors.com</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <SocialButton onClick={() => window.open('https://www.facebook.com/profile.php?id=100083232817286', '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>
                        <SocialButton onClick={() => window.open('https://www.youtube.com/@heatventors', '_blank')} inverted socialIcon={<YouTubeIcon/>}/>
                        <SocialButton onClick={() => window.open('https://twitter.com/heatventors', '_blank')} inverted socialIcon={<TwitterIcon/>}/>
                    </Stack>
                </Stack>

                <Stack xs={12} md={6} lg={2}>
                    <div className="dino-footer-container__own-logo">
                        <img src={greenLogo} alt="Dinobooking logo" width="300px"/>
                        {/*<img onClick={() => window.location.href = 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'} src={simpleLogo} alt="SimplePay logo" style={{cursor: 'pointer'}} width="150px"/>*/}
                    </div>
                </Stack>
            </Stack>

        </div>
    </div>

}

export default DinoFooter