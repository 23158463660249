import {Box, Button, Collapse, Grid, IconButton, Stack, styled, TextField, Typography, useTheme} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useContext, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import _ from "lodash";
import ErrorContext from "../../context/ErrorContext";


const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const YearlyPrice = ({...price}) => {
    const theme = useTheme()
    const [yearlyPriceOg, setYearlyPriceOg] = useState(price)
    const [yearlyPrice, setYearlyPrice] = useState(price)
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(false)
    const {updateData} = useCallDataApi('energy-prices')
    const {setExpectedError} = useContext(ErrorContext)

    const handleExpandClick = () => setExpanded(!expanded)

    const avgPrice = () => {
        const sum = yearlyPrice.jan + yearlyPrice.feb + yearlyPrice.mar + yearlyPrice.apr + yearlyPrice.may +
            yearlyPrice.jun + yearlyPrice.jul + yearlyPrice.aug + yearlyPrice.sep + yearlyPrice.oct +
            yearlyPrice.nov + yearlyPrice.dec
        const num = sum / 12;
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    const updateYearlyPrice = () => {
        setLoading(true)
        if (Object.values(yearlyPrice).includes(NaN)) {
            setExpectedError({error: true, message: ['Every field is required!']})
            setLoading(false)
            return
        }
        updateData(`${yearlyPrice?.id}/`, yearlyPrice)
            .then(p => {
                setYearlyPrice(p)
                setYearlyPriceOg(p)
            })
            .finally(() => setLoading(false))
    }

    const edited = () => !_.isEqual(yearlyPrice, yearlyPriceOg)

    return <Stack spacing={2} marginTop={4} className="dashboard-card">
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        }}>
            <Typography color={theme.palette.primary[500]} variant='headline6'>{yearlyPrice.year}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '15px'
            }}>
                <Stack spacing={1}>
                    <Typography color={theme.palette.primary[500]} variant='caption'>YEARLY AVG Price (/KWH)</Typography>
                    <TextField variant='standard' type='number' disabled value={avgPrice()}/>
                </Stack>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon sx={{color: theme.palette.primary[500]}}/>
                </ExpandMore>
            </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>January</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, jan: parseFloat(e.target.value)})} value={yearlyPrice?.jan}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>April</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, apr: parseFloat(e.target.value)})} value={yearlyPrice?.apr}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>July</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, jul: parseFloat(e.target.value)})} value={yearlyPrice?.jul}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>October</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, oct: parseFloat(e.target.value)})} value={yearlyPrice?.oct}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>February</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, feb: parseFloat(e.target.value)})} value={yearlyPrice?.feb}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>May</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, may: parseFloat(e.target.value)})} value={yearlyPrice?.may}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>August</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, aug: parseFloat(e.target.value)})} value={yearlyPrice?.aug}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>November</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, nov: parseFloat(e.target.value)})} value={yearlyPrice?.nov}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>March</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, mar: parseFloat(e.target.value)})} value={yearlyPrice?.mar}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>June</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, jun: parseFloat(e.target.value)})} value={yearlyPrice?.jun}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>September</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, sep: parseFloat(e.target.value)})} value={yearlyPrice?.sep}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} xl={3}>
                    <Stack spacing={2}>
                        <Typography color={theme.palette.primary[500]} variant='caption'>December</Typography>
                        <TextField variant='standard' type='number' onChange={e=>setYearlyPrice({...yearlyPrice, dec: parseFloat(e.target.value)})} value={yearlyPrice?.dec}/>
                    </Stack>
                </Grid>

            </Grid>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
                <Button variant='contained' onClick={updateYearlyPrice} disabled={!edited()}>{edited() ? 'Save' : 'Saved'}</Button>
            </Box>
        </Collapse>

        <Loading isLoading={loading}/>
    </Stack>
}

export default YearlyPrice