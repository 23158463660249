import React, {PureComponent} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

// const data = [
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ];

const HeatLineChart = ({data}) => {
    return <div style={{position: 'relative', width: '100%', paddingBottom: '550px'}}>
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            }}
        ><ResponsiveContainer width="99%" height="100%">
        <LineChart width={500} height={1000} data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="timestamp" />
            {/*<YAxis domain={["auto", "dataMax + 500"]}/>*/}
            <YAxis />
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dataKey="heat1" name="HeatTank thermal cons" stroke="#FB5A6D"/>
            <Line type="monotone" dataKey="heat2" name="System thermal cons" stroke="#5ABDD7"/>
        </LineChart>
        </ResponsiveContainer></div></div>
}

export default HeatLineChart