import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';


const ConsumptionLineChart = ({data}) => {
    return <div style={{position: 'relative', width: '100%', paddingBottom: '550px'}}>
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            }}
        ><ResponsiveContainer width="99%" height="100%">
        <LineChart width={500} height={1000} data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="timestamp" />
            {/*<YAxis domain={["auto", "dataMax + 500"]}/>*/}
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dataKey="fogy1" name="Consumption with HeatTank" stroke="#FB5A6D"/>
            <Line type="monotone" dataKey="consumption_without_heattank" name="Consumption without HeatTank" stroke="#5ABDD7"/>
        </LineChart>
        </ResponsiveContainer></div></div>
}

export default ConsumptionLineChart