import AuthBase from "../AuthBase";
import {
    Box, Button,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack, TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import CircularProgressWithLabel from "../../components/Progress";
import EnergySavingsLeafRoundedIcon from '@mui/icons-material/EnergySavingsLeafRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MyBarChart from "../../components/charts/BarChart";
import HeatLineChart from "../../components/charts/HeatLineChart";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import useCallDataApi from "../../hooks/data";
import {thousandSeparator} from "../../utils/numberUtils";
import Loading from "../../components/Loading";
import TemperatureLineChart from "../../components/charts/TemperatureLineChart";
import ConsumptionLineChart from "../../components/charts/ConsumptiponLineChart";
import { format } from 'date-fns'

const Dashboard = () => {
    const {user} = useContext(AuthContext)
    const today = new Date()
    const todayMorning = new Date()
    todayMorning.setHours(0,0,0,0)
    const {getData: fetchDashboard, postData: postDashboard} = useCallDataApi('dashboard')
    const [data, setData] = useState({})
    const [selectedYear, setSelectedYear] = useState(today.getFullYear())
    const [loading, setLoading] = useState({energy: true, heat: false, consumption: false, temperature: false, main: true})
    const [constantData, setConstantData] = useState({})
    const [chargeData, setChargeData] = useState({})
    const [yearlyData, setYearlyData] = useState({})
    const [heatData, setHeatData] = useState([])
    const [heatDate, setHeatDate] = useState(today)
    const [temperatureDates, setTemperatureDates] = useState({start: todayMorning, end: today})
    const [temperatureData, setTemperatureData] = useState([])
    const [consumptionDates, setConsumptionDates] = useState({start: todayMorning, end: today})
    const [consumptionData, setConsumptionData] = useState([])
    const [charts, setCharts] = useState({
        energy_consumption: true,
        heat_consumption: true,
        temperature_value: true,
        consumption_values: true,
    })
    const theme = useTheme()

    const handleYearChange = (event) => setSelectedYear(event.target.value)
    const handleHeatDateChange = (newValue) => setHeatDate(newValue)
    const handleTempDateChange = (newValue, edge) => setTemperatureDates({...temperatureDates, [edge]: newValue})
    const handleConsumptionDateChange = (newValue, edge) => setConsumptionDates({...consumptionDates, [edge]: newValue})

    useEffect(() => {
        document.title = 'Dashboard'
        const getDashboardData = async () => {
            const [s, c, d, cm, con, temp] = await Promise.all([
                fetchDashboard('summary'),
                fetchDashboard('constant_data'),
                fetchDashboard('charge_data'),
                fetchDashboard('charts'),
                postDashboard('consumption_values/', {
                    start: format(consumptionDates?.start, 'yyyy-MM-dd HH:mm'),
                    end: format(consumptionDates?.end, 'yyyy-MM-dd HH:mm')
                }),
                postDashboard('temperature_values/', {
                    start: format(temperatureDates?.start, 'yyyy-MM-dd HH:mm'),
                    end: format(temperatureDates?.end, 'yyyy-MM-dd HH:mm')
                })
            ])
            if (s) setData(s)
            if (c) setConstantData(c)
            if (d) setChargeData(d)
            if (cm) setCharts(cm)
            if (con) setConsumptionData(con)
            if (temp) setTemperatureData(temp)
        }
        getDashboardData().finally(() => setLoading(prev => ({...prev, main: false, energy: false})))

        const tenSec = 1000 * 30
        const int = setInterval(() => {
            fetchDashboard('charge_data').then(d => setChargeData(d))
        }, tenSec)
        return () => clearInterval(int)
    }, [])

    useEffect(() => {
        setLoading(prev => ({...prev, energy: true}))
        postDashboard('energy_consumptions/', {year: selectedYear})
            .then(d => setYearlyData(d))
            .finally(() => setLoading(prev => ({...prev, energy: false})))
    }, [selectedYear])

    const updateHeatConsumption = () => {
        setLoading(prev => ({...prev, heat: true}))
        postDashboard('heat_consumptions/', {date: format(heatDate, 'yyyy-MM-dd')})
            .then(d => setHeatData(d))
            .finally(() => setLoading(prev => ({...prev, heat: false})))
    }

    const updateTemperatureDates = () => {
        setLoading(prev => ({...prev, temperature: true}))
        postDashboard('temperature_values/', {
            start: format(temperatureDates?.start, 'yyyy-MM-dd HH:mm'),
            end: format(temperatureDates?.end, 'yyyy-MM-dd HH:mm')
        })
            .then(d => setTemperatureData(d))
            .finally(() => setLoading(prev => ({...prev, temperature: false})))
    }

    const updateConsumptionDates = () => {
        setLoading(prev => ({...prev, consumption: true}))
        postDashboard('consumption_values/', {
            start: format(consumptionDates?.start, 'yyyy-MM-dd HH:mm'),
            end: format(consumptionDates?.end, 'yyyy-MM-dd HH:mm')
        })
            .then(d => setConsumptionData(d))
            .finally(() => setLoading(prev => ({...prev, consumption: false})))
    }

    const createYearList = () => {
        const fromYear = parseInt(constantData?.operation_started?.split('-')[0]) || 2020
        const result = []
        for (let i = fromYear; i <= today.getFullYear(); i++) {
            result.push(i)
        }
        return result
    }

    return <AuthBase>
        <div className="main">
            <Typography color={theme.palette.special['blue']} variant='headline3'>Welcome, {user?.name}</Typography>
            <Typography color={theme.palette.secondary[500]} variant='subtitle2'>Find comprehensive data and statistics
                on energy consumption, temperature, and savings.</Typography>

            <Grid container spacing={3} marginTop={4}>
                <Grid item xs={12} lg={4}>
                    <div className='dashboard-card'>
                        <Stack direction='row' spacing={2} sx={{width: '100%'}} justifyContent='space-between'>
                            <Stack>
                                <Typography variant='headline5' color='#3FAF58'>{thousandSeparator(data?.energy_saved)} kWh</Typography>
                                <Typography variant='button' color={theme.palette.secondary[500]}>Energy
                                    saved</Typography>
                            </Stack>
                            <Box className='icon-box' sx={{background: theme.palette.special['blue']}}>
                                <EnergySavingsLeafIcon sx={{color: '#8AE669'}}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <div className='dashboard-card'>
                        <Stack direction='row' spacing={2} sx={{width: '100%'}} justifyContent='space-between'>
                            <Stack>
                                <Typography variant='headline5' color={theme.palette.special['blue']}>{thousandSeparator(data?.euros_saved)} €</Typography>
                                <Typography variant='button' color={theme.palette.secondary[500]}>Euros
                                    saved</Typography>
                            </Stack>
                            <Box className='icon-box' sx={{background: theme.palette.special['blue']}}>
                                <CurrencyExchangeRoundedIcon sx={{color: theme.palette.special['light']}}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <div className='dashboard-card'>
                        <Stack direction='row' spacing={2} sx={{width: '100%'}} justifyContent='space-between'>
                            <Stack>
                                <Typography variant='headline5' color={theme.palette.special['blue']}>{constantData?.estimated_time_of_pay_off}</Typography>
                                <Typography variant='button' color={theme.palette.secondary[500]}>Estimated time of
                                    pay-offd</Typography>
                            </Stack>
                            <Box className='icon-box' sx={{background: theme.palette.special['blue']}}>
                                <LoopRoundedIcon sx={{color: theme.palette.special['light']}}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <div className='dashboard-card' style={{height: '100%'}}>
                        <Typography variant='headline6' color={theme.palette.special['blue']}>Main
                            informations</Typography>
                        <Stack spacing={2} marginTop={1.5} sx={{width: '100%'}}>
                            <Stack direction='row' sx={{width: '100%'}} justifyContent='space-between'>
                                <Box>
                                    <Typography color={theme.palette.secondary[600]} variant='button'>size</Typography>
                                    <Typography variant='subtitle2'>{thousandSeparator(constantData?.size)} m2</Typography>
                                </Box>
                                <Box>
                                    <Typography color={theme.palette.secondary[600]} variant='button'>type</Typography>
                                    <Typography variant='subtitle2'>{constantData?.area_type}</Typography>
                                </Box>
                            </Stack>
                            <Box>
                                <Typography color={theme.palette.secondary[600]} variant='button'>Electricity
                                    consumption (cooling)</Typography>
                                <Typography variant='subtitle2'>{thousandSeparator(constantData?.electric_consumption)} kWh/year</Typography>
                            </Box>
                            <Box>
                                <Typography color={theme.palette.secondary[600]} variant='button'>Location</Typography>
                                <Typography variant='subtitle2'>{constantData?.loaction}</Typography>
                            </Box>
                            <Box>
                                <Typography color={theme.palette.secondary[600]} variant='button'>Operation
                                    started</Typography>
                                <Typography variant='subtitle2'>{constantData?.operation_started}</Typography>
                            </Box>
                            <Stack>
                                <Typography color={theme.palette.secondary[600]} variant='button'>Other
                                    information</Typography>
                                <Typography color={theme.palette.secondary[600]} variant='button'>{constantData?.other_information}</Typography>
                            </Stack>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <div className='dashboard-card'
                         style={{padding: '32px', height: '100%', background: 'linear-gradient(to right, #1B425C, #092F49)'}}>
                        <Box sx={{width: '100%'}}>
                            <Typography variant='headline6' color='#fff'>HeatTanks</Typography>
                        </Box>
                        <Box
                            sx={{width: '100%', display: 'flex', gap: '10px', flexDirection: {xs: 'column', md: 'column', lg: 'row'}, alignItems: {xs: 'center', md: 'center', lg: 'space-between'}, justifyContent: {xs: 'center', md: 'center', lg: 'space-between'}}}
                            marginTop={2}>
                            {chargeData?.state && <Box>
                                <CircularProgressWithLabel {...chargeData}/>
                            </Box>}
                            <Grid >
                                <Stack>
                                    <Typography color='white' variant='headline6'>{constantData?.heat_tank_type} <Typography
                                        color={theme.palette.primary[500]} variant='headline6'>({constantData?.number_of_heat_tanks}
                                        pcs)</Typography></Typography>
                                    <Typography color={theme.palette.secondary[500]} variant='button'>type</Typography>
                                </Stack>
                                <Grid container marginTop={4} spacing={2}>
                                    <Grid item xs={6} xl={6}>
                                        <Stack>
                                            <Typography color='white' variant='headline6'>{constantData?.capacity} kWh</Typography>
                                            <Typography color={theme.palette.secondary[500]}
                                                        variant='button'>capacity</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} xl={6}>
                                        <Stack>
                                            <Typography color='white' variant='headline6'>{thousandSeparator(constantData?.max_performance)} kWh</Typography>
                                            <Typography color={theme.palette.secondary[500]} variant='button'>Max
                                                performance</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={6} xl={6}>
                                        <Stack>
                                            <Typography color='white' variant='headline6'>{constantData?.phase_change_temperature} °C</Typography>
                                            <Typography color={theme.palette.secondary[500]} variant='button'>phase
                                                change temperature</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} xl={6}>
                                        <Stack>
                                            <Typography color='white' variant='headline6'>{constantData?.operation_started}</Typography>
                                            <Typography color={theme.palette.secondary[500]} variant='button'>Operation
                                                started</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                    </div>
                </Grid>
                {charts?.energy_consumption && <Grid item xs={12} lg={12} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <div className="dashboard-card">
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant='headline6' sx={{color: theme.palette.secondary[600]}}>Energy
                                consumption</Typography>
                            <FormControl>
                                {/*<InputLabel id="demo-simple-select-label">Age</InputLabel>*/}
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedYear}
                                    // label="Age"
                                    // variant='standard'
                                    onChange={handleYearChange}
                                >
                                    {createYearList().map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Stack spacing={4} sx={{width: '100%', flexDirection: {xs: 'column', md: 'column', lg: 'row'}}}>
                            <Box sx={{width: {xs: '100%', md: '100%', lg: '70%'}, minHeight: '500px'}}>
                                <MyBarChart data={yearlyData?.chart}/>
                            </Box>
                            <Box sx={{
                                width: {xs: '100%', md: '100%', lg: '30%'},
                                padding: '15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column'
                            }}>
                                <Typography sx={{color: theme.palette.secondary[600]}}
                                            variant='headline6'>Summary</Typography>
                                <Typography sx={{color: theme.palette.secondary[500]}}
                                            variant='subtitle2'>{selectedYear} jan - dec</Typography>
                                <Divider/>
                                <Typography sx={{color: theme.palette.primary[600]}}
                                            variant='subtitle1'>{thousandSeparator(yearlyData?.energy_consumption_without_heattank)} kWh</Typography>
                                <Typography sx={{color: theme.palette.secondary[500]}} variant='button'>Energy
                                    consumption without heattank</Typography>

                                <Typography sx={{color: '#4599AF'}}
                                            variant='subtitle1'>{thousandSeparator(yearlyData?.energy_consumption_with_heattank)} kWh</Typography>
                                <Typography sx={{color: theme.palette.secondary[500]}} variant='button'>Energy
                                    consumption with heattank</Typography>
                                <Divider/>
                                <Stack direction='row' spacing={2}>
                                    <Stack>
                                        <Typography sx={{color: '#3FAF58'}}
                                                    variant='headline6'><EnergySavingsLeafRoundedIcon/>{thousandSeparator(yearlyData?.energy_saved)} kWh</Typography>
                                        <Typography sx={{color: theme.palette.secondary[500]}} variant='button'>Energy
                                            saved</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography sx={{color: theme.palette.secondary[600]}}
                                                    variant='headline6'>{thousandSeparator(yearlyData?.euros_saved)} €</Typography>
                                        <Typography sx={{color: theme.palette.secondary[500]}} variant='button'>Euros
                                            saved</Typography>
                                    </Stack>
                                </Stack>

                            </Box>
                        </Stack>
                    </div>
                </Grid>}
                {charts?.heat_consumption && <Grid item xs={12} lg={12} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <div className="dashboard-card">
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant='headline6' sx={{color: theme.palette.secondary[600]}}>Heat
                                consumption</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                                <DatePicker
                                    disableFuture
                                    value={heatDate}
                                    inputFormat="YYYY-MM-DD"
                                    mask="____-__-__"
                                    onChange={handleHeatDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <Button startIcon={<LoopRoundedIcon/>} variant='outlined' onClick={() => updateHeatConsumption()}>update</Button>
                                </Box>
                            </LocalizationProvider>
                        </Box>
                        <Stack spacing={4} sx={{width: '100%', flexDirection: {xs: 'column', md: 'column', lg: 'row'}}}>
                            <Box sx={{width: {xs: '100%', md: '100%', lg: '100%'}, minHeight: '500px'}}>
                                <HeatLineChart data={heatData?.chart}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>}
                {charts?.temperature_value && <Grid item xs={12} lg={12} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <div className="dashboard-card">
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant='headline6' sx={{color: theme.palette.secondary[600]}}>Temperature
                                value</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <DateTimePicker
                                        disableFuture
                                        value={temperatureDates?.start}
                                        ampm={false}
                                        onChange={newVal => handleTempDateChange(newVal, 'start')}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    -
                                    <DateTimePicker
                                        disableFuture
                                        value={temperatureDates?.end}
                                        ampm={false}
                                        onChange={newVal => handleTempDateChange(newVal, 'end')}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <Button startIcon={<LoopRoundedIcon/>} variant='outlined' onClick={() => updateTemperatureDates()}>update</Button>
                                </Box>
                            </LocalizationProvider>
                        </Box>
                        <Stack spacing={4} sx={{width: '100%', flexDirection: {xs: 'column', md: 'column', lg: 'row'}}}>
                            <Box sx={{width: {xs: '100%', md: '100%', lg: '100%'}, minHeight: '500px'}}>
                                <TemperatureLineChart data={temperatureData?.chart}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>}
                {charts?.consumption_values && <Grid item xs={12} lg={12} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                    <div className="dashboard-card">
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant='headline6' sx={{color: theme.palette.secondary[600]}}>Consumption
                                values</Typography>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <DateTimePicker
                                        disableFuture
                                        value={consumptionDates?.start}
                                        ampm={false}
                                        onChange={newVal => handleConsumptionDateChange(newVal, 'start')}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    -
                                    <DateTimePicker
                                        disableFuture
                                        value={consumptionDates?.end}
                                        ampm={false}
                                        onChange={newVal => handleConsumptionDateChange(newVal, 'end')}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <Button startIcon={<LoopRoundedIcon/>} variant='outlined' onClick={() => updateConsumptionDates()}>Update</Button>
                                </Box>
                            </LocalizationProvider>
                        </Box>
                        <Stack spacing={4} sx={{width: '100%', flexDirection: {xs: 'column', md: 'column', lg: 'row'}}}>
                            <Box sx={{width: {xs: '100%', md: '100%', lg: '100%'}, minHeight: '500px'}}>
                                <ConsumptionLineChart data={consumptionData?.chart}/>
                            </Box>
                        </Stack>
                    </div>
                </Grid>}
            </Grid>
        </div>

        <Loading isLoading={Object.values(loading).includes(true)}/>

    </AuthBase>
}

export default Dashboard