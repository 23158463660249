import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useTheme} from "@mui/material";
import whiteLogo from "../assets/logos/HV_v2_logo.png"
import {Link} from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthContext";
import DinoDialog from "./Dialog";
import packageJson from '../../package.json'
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const {w} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)

    const theme = useTheme()

    const [menuItems, setMenuItems] = useState([
        {name: 'Dashboard', url: '/', icon: <DashboardRoundedIcon sx={{color: theme.palette.special['blue']}}/>},
        {name: 'Energy prices', url: '/energy', icon: <AttachMoneyRoundedIcon sx={{color: theme.palette.special['blue']}}/>},
        {name: 'Contact', url: '/contact', icon: <CallRoundedIcon sx={{color: theme.palette.special['blue']}}/>},

    ])
    const {logoutUser} = useContext(AuthContext)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div style={{background: '#fff'}}>

            <Toolbar style={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff'}}>
                <Box sx={{ padding: '20px'}}>
                    <img src={whiteLogo} alt="Heat Ventors logo" width='100px'/>
                </Box>
            </Toolbar>
            <Divider sx={{marginLeft: 2, marginRight: 2, background: '#E2E8EB'}}/>
            <List sx={{padding: '32px 16px'}}>
                {menuItems.map((item, index) => (
                    <ListItem key={item.name} className="menu-item" disablePadding sx={{paddingBottom: '3px'}}
                              component={Link}
                              to={item.url}>
                        <ListItemButton className="menu-button" sx={{"&:hover": {background: '#EEF6F8'}}}>
                            <ListItemIcon className="menu-button__icon">
                                {item.icon}
                            </ListItemIcon>
                            <Typography color={theme.palette.special['blue']} variant="button">{item.name}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Typography variant='caption' sx={{
                color: '#fff',
                position: 'absolute',
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }}>v. {packageJson.version}</Typography>
            <Button variant='text' sx={{
                color: theme.palette.special['blue'],
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }} startIcon={<LogoutRoundedIcon/>} onClick={() => setLogoutDialogOpen(true)}>Logout</Button>
        </div>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    const logoutActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setLogoutDialogOpen(false)}>Go back</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setLogoutDialogOpen(false)
            logoutUser()
        }} endIcon={<LogoutRoundedIcon/>}>Logout</Button>
    </>

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar sx={{
                width: {md: `calc(100% - ${drawerWidth}px)`},
                ml: {md: `${drawerWidth}px`},
                display: {xs: 'flex', sm: 'flex', md: 'none', lg: 'none'},
                background: {xs: theme.palette.primary[600], sm: theme.palette.primary[600], md: '#fff'}
            }} position="fixed">
                <Container maxWidth="xl">

                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 0, display: {xs: 'flex', sm: 'flex', md: 'none'}, padding: '10px'}}>
                            <img src={whiteLogo} alt="HeatVentors logo" height='40px'/>
                        </Box>
                        <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                            <div style={{color: '#fff'}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    {!mobileOpen ? <MenuIcon/> : <CloseIcon/>}
                                </IconButton>
                            </div>
                        </Box>

                    </Toolbar>
                </Container>

            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: drawerWidth}, flexShrink: {sm: 0},'& .MuiDrawer-paper': {border: 'none', boxShadow: '0 4px 20px #0C54891A'}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {sm: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'none', md: 'block'},
                        boxShadow: '0 10px 10px black',
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, width: {sm: '100%', md: `calc(100% - ${drawerWidth}px)`}}}
            >

                {props.children}

                <DinoDialog open={logoutDialogOpen}
                            handleClose={() => setLogoutDialogOpen(false)}
                            title='Logout'
                            subtitle='Are you sure you want to logout?'
                            actions={logoutActions}>

                </DinoDialog>
            </Box>
        </Box>
    );
}

export default ResponsiveDrawer;