import {createContext, useContext, useState} from "react";
import DinoDialog from "../components/Dialog";
import ConfigContext from "./ConfigContext";
import {Stack, Typography} from "@mui/material";

const ErrorContext = createContext();

export default ErrorContext;

export const ErrorProvider = ({ children }) => {
    const [unexpectedError, setUnexpectedError] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [expectedError, setExpectedError] = useState({error: false, message: []})

    const contextData = {
        setUnexpectedError: setUnexpectedError,
        setNetworkError: setNetworkError,
        setExpectedError: setExpectedError
    }

    return <ErrorContext.Provider value={contextData}>
        {children}

        <DinoDialog open={unexpectedError}
                    title='Unexpected error!'
                    subtitle='Please reload the page'
            // actions={deleteActions}
                    handleClose={() => setUnexpectedError(false)}>
        </DinoDialog>

        <DinoDialog open={networkError}
                    title='Service unavailable!'
                    subtitle='Please visit the site later'
            // actions={deleteActions}
                    handleClose={() => setNetworkError(false)}>
        </DinoDialog>


        <DinoDialog open={expectedError.error}
                    title='Error!'
                    subtitle='Please correct the error(s) below!'
            // actions={deleteActions}
                    handleClose={() => setExpectedError({error: false, message: []})}>
            <Stack spacing={1}>
                {expectedError.message?.map(e => <Typography key={`err_${e}`} variant='body2' color='#ff0000'>{e}</Typography>)}
            </Stack>
        </DinoDialog>
    </ErrorContext.Provider>
}