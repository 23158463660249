import AuthDrawer from "../../components/AuthDrawer";
import {Grid, Stack, Typography, useTheme} from "@mui/material";
import SocialButton from "../../components/SocialButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from '../../assets/logos/logo.png'
import AuthBase from "../AuthBase";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Contact = () => {
    const theme = useTheme()
    document.title = 'Contact'
    return <AuthBase>
        <div className="main">
            <Typography color={theme.palette.special['blue']} variant='headline3'>Contact</Typography>
            <Typography color={theme.palette.secondary[500]} variant='subtitle2'>Need some help? Contact us!</Typography>
            <Grid container spacing={3} marginTop={3}>
                <Grid item xs={12} md={6} xl={6}>
                    <div className="dashboard-card" style={{justifyContent: 'space-between', alignItems: 'start', width: '100%', padding: '2rem'}}>
                        <Typography variant='headline6' color={theme.palette.special['blue']}>Contact us!</Typography>
                        <Stack spacing={1.5} marginTop={3}>
                            <Typography variant='contact' color={theme.palette.primary[500]}>+36 20 822 7134</Typography>
                            <Typography variant='contact' color={theme.palette.primary[500]}>info@heatventors.com</Typography>
                            <Typography variant='contact' onClick={() => window.open('https://www.heatventors.com', '_blank')} color={theme.palette.primary[500]}>heatventors.com</Typography>
                            <Stack direction='row' spacing={2}>
                                <SocialButton onClick={() => window.open('https://www.facebook.com/profile.php?id=100083232817286', '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>
                                <SocialButton onClick={() => window.open('https://www.youtube.com/@heatventors', '_blank')} inverted socialIcon={<YouTubeIcon/>}/>
                                <SocialButton onClick={() => window.open('https://twitter.com/heatventors', '_blank')} inverted socialIcon={<TwitterIcon/>}/>
                            </Stack>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <div className="dashboard-card" style={{height: '100%'}}>
                        <img src={logo} alt="" style={{width: '100%'}}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    </AuthBase>
}

export default Contact