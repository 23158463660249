import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import heatVentorsTheme from "./components/theme/HetventorsTheme";

import NotFound from "./pages/404";
import Login from "./pages/authentication/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import PrivateRoute from "./utils/PrivateRoute";
import {ErrorProvider} from "./context/ErrorContext";
import Dashboard from "./pages/dashboard/Dashboard";
import Contact from "./pages/contact/Contact";
import EnergyPrices from "./pages/energyPrices/EnergyPrices";


function App() {

  return <ThemeProvider theme={heatVentorsTheme}>
    <ConfigProvider>
      <ErrorProvider>
        <Router>
          <AuthProvider>
            <Switch>


              <Route path="/login" component={Login} exact/>
              <PrivateRoute path="/" component={Dashboard} exact/>
              <PrivateRoute path="/contact" component={Contact} exact/>
              <PrivateRoute path="/energy" component={EnergyPrices} exact/>

              <Route component={NotFound}/>
            </Switch>
          </AuthProvider>
        </Router>
      </ErrorProvider>
    </ConfigProvider>
  </ThemeProvider>
}

export default App;