import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null);
    const [permissions, setPermissions] = useState(() => localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const [authLoading, setAuthLoading] = useState(false)
    const history = useHistory();

    const setAuthStorage = (data) => {
        // console.log('update')
        // console.log(data)
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        // console.log('data: ', data)
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", u?.permissions);
    }

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${baseUrl}/api/token/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email: e.target.username.value, password: e.target.password.value}),
            });
            const data = await response.json();
            if (response.status === 200) {
                setAuthStorage(data)
                history.push("/")
            } else {
                alert("Hiba, helytelen email vagy jelszó!");
            }
        } catch (e) {
            console.log(e)
            alert("Hiba, a bejelentkezés során, próbálja újra később!");
        }

    };


    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        setPermissions(null);
        localStorage.removeItem("authTokens");
        localStorage.removeItem("permissions");
    }, []);

    const updateToken = useCallback(async () => {
        const response = await fetch(`${baseUrl}/api/token/refresh/`, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify({refresh: authTokens?.refresh}),
        });

        const data = await response.json();
        if (response.status === 200) setAuthStorage(data)
        else logoutUser()

    }, [authTokens?.refresh, logoutUser]);



    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        permissions: permissions,
        authLoading: authLoading,
        setAuthLoading: setAuthLoading,
    };

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4;

        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, updateToken]);

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};