import sadDino from '../assets/dramatic-cry.gif'
import Typography from "@mui/material/Typography";
import Base from "./authentication/Base";
import {Button, Stack, useTheme} from "@mui/material";
import {useHistory} from "react-router-dom";

const NotFound = () => {
    const theme = useTheme()
    const history = useHistory()
    return <Base>
        <div className="main">
            <Stack spacing={2} marginBottom={2}>
                <Typography variant="headline3" color={theme.palette.special['blue']}>I cant't find this page!</Typography>
                <img src={sadDino} alt="sad dino" style={{width: '20%'}}/>
            </Stack>
            <Button variant='contained' onClick={() => history.push('/')}>Go home</Button>

        </div>
    </Base>

}

export default NotFound