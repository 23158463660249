const typography = {
    headline1: {
        fontFamily: 'Open Sans',
        fontSize: '3.5rem',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        '@media (max-width: 780px)': {
            fontSize: "40px"
        }
    },
    headline2: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "40px",
        lineHeight: "44px",
        '@media (max-width: 780px)': {
            fontSize: "30px"
        }
    },
    headline3: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "38px",
        lineHeight: "normal",
    },
    headline4: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "37px",
    },
    headline5: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "30px",
    },
    headline6: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "26px",
    },
    body1: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '16px',
    },
    body2: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
    },
    subtitle1: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
    },
    subtitle2: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: 'normal',
    },
    caption: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '13px',
        letterSpacing: '0.01em',
        textTransform: 'uppercase'
    },
    button: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        textTransform: 'lowercase capitalize',
    },
    buttonNormal: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
    },
    overline: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '11px',
        textTransform: 'uppercase',
        letterSpacing: '0.015em'
    },
    contact: {
        fontFamily: 'Comfortaa',
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    progress: {
        fontFamily: 'Comfortaa',
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '53px',
        letterSpacing: '0px',
        textAlign: 'center'
    },
    progressState: {
        fontFamily: 'Comfortaa',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '17px',
        letterSpacing: '0px',
        textAlign: 'center',
    },
}

export default typography