import AuthDrawer from "../../components/AuthDrawer";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import YearlyPrice from "./YearlyPrice";
import Loading from "../../components/Loading";


const EnergyPrices = () => {
    const {getData} = useCallDataApi('energy-prices')
    const [energyPrices, setEnergyPrices] = useState([])
    const [loading, setLoading] = useState(true)

    const theme = useTheme()

    const [currency, setCurrency] = useState('eur');

    const handleChange = (event) => setCurrency(event.target.value)

    useEffect(() => {
        document.title = 'Energy prices'
        getData('get_prices_for_user').then(e => {
            setEnergyPrices(e)
            setLoading(false)
        })
    }, [])

    return <AuthDrawer>
        <div className="main">
            <Typography color={theme.palette.special['blue']} variant='headline3'>Energy prices</Typography>
            <Typography color={theme.palette.secondary[500]} variant='subtitle2'>Take control of your energy costs -
                input and customize your energy prices effortlessly</Typography>

            <Box sx={{
                width: '100%',
                marginTop: 8,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant='headline6' color={theme.palette.special['blue']}>Energy prices by year</Typography>
                <Stack spacing={1}>
                    <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>CURRENCY</Typography>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currency}
                            disabled
                            variant='standard'
                            onChange={handleChange}
                        >
                            <MenuItem value={'eur'}>Euro (€)</MenuItem>
                            <MenuItem value={'huf'}>Forint</MenuItem>
                            <MenuItem value={'usd'}>Dollar ($)</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            {energyPrices?.map(p => <YearlyPrice key={p.id} {...p}/>)}
        </div>

        <Loading isLoading={loading}/>
    </AuthDrawer>
}

export default EnergyPrices