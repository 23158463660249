import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./typography";
import {components} from "./componentDef";


const primary = {
    '900': '#570F19',
    '800': '#821725',
    '700': '#A82435',
    'A700': '#A82435',
    '600': '#C6394C',
    '500': '#EA495E',
    '400': '#EF7686',
    'A400': '#EF7686',
    '300': '#F193A0',
    '200': '#F6ACB6',
    'A200': '#F6ACB6',
    '100': '#FCCAD0',
    'A100': '#FCCAD0',
    '50': '#FCE8EB',
    '20': '#FFF5F6'
}


const secondary = {
    '900': '#09120D',
    '800': '#0B0E0F',
    '700': '#1C2427',
    'A700': '#1C2427',
    '600': '#38474D',
    '500': '#536B74',
    '400': '#6F8E9A',
    'A400': '#6F8E9A',
    '300': '#8CA5AE',
    '200': '#A9BBC2',
    'A200': '#A9BBC2',
    '100': '#C5D2D7',
    'A100': '#C5D2D7',
    '50': '#DFE0E6',
    '20': '#F3F5F7'
}

const special = {
    'blue': '#1B425C',
    'light': '#5ABDD7'
}


const heatVentorsTheme = createTheme({
        components: components,
        name: 'green',
        palette: {
            primary: primary,
            secondary: secondary,
            special: special
        },
        typography: typography
    },
    huHU
)

export default heatVentorsTheme